
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { useField } from "vee-validate";

import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as yup from "yup";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "account-settings",
  setup() {
    const { errorMessage, meta, value } = useField("fieldName");
    const router = useRouter();
    let reg = /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{6,12}$/;
    const dataPassword = ref({
      user_id: store.getters.currentUser["_id"],
      old_password: "",
      new_password: "",
      confirm_password: "",
    });
    const rules = ref({
      old_password: [
        {
          required: true,
          message: "Mật khẩu không được để trống",
          trigger: "blur",
        },
        {
          min: 6,
          message: "Tối thiểu 6 ký tự",
          trigger: "blur",
        },
      ],
      new_password: [
        {
          required: true,
          message: "Mật khẩu mới không được để trống",
          trigger: "blur",
        },
        {
          min: 6,
          message: "Tối thiểu 10 ký tự",
          trigger: "blur",
        },
        {
          validator: (rule, value, callback) => {
            if (dataPassword.value.old_password === value) {
              callback(new Error("Mật khẩu mới trùng với mật khẩu cũ."));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
      confirm_password: [
        { required: true, message: "Không được để trống", trigger: "blur" },
        {
          validator: (rule, value, callback) => {
            if (dataPassword.value.new_password !== value) {
              callback(new Error("Xác nhận mật khẩu không trùng "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
    });
    const showOldPassword = ref(true);
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Thay đổi mật khẩu", ["Account"]);
      const currentUser = store.getters["currentUser"];
      if (currentUser.password === "") {
        rules.value["old_password"][0]["required"] = false;
        showOldPassword.value = false;
      } else {
        rules.value["old_password"][0]["required"] = true;
        showOldPassword.value = true;
      }
    });
    const formRef = ref<null | HTMLFormElement>(null);
    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          store
            .dispatch(Actions.CHANGE_PASSWORD, dataPassword.value)
            .then(() => {
              Swal.fire({
                text: "Cập nhật thông tin thành công",
                icon: "success",
                buttonsStyling: false,
                showCancelButton: false,
                timer: 1000,
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-primary",
                },
              }).then(function (result) {
                // Go to page after successfully login
                router.push({ name: "sign-in" });
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Thử lại!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        } else {
          return false;
        }
      });
    };
    return {
      rules,
      showOldPassword,
      dataPassword,
      submit,
      formRef,
    };
  },
});
